export {}

interface DataLayerEventOptions {
  [key: string]: unknown
}

export const pushToDataLayer = (eventName: string | null, options: DataLayerEventOptions = {}) => {
  window.dataLayer = window.dataLayer || []
  if (eventName) {
    window.dataLayer.push({
      event: eventName,
      ...options,
    })
  } else {
    window.dataLayer.push({
      ...options,
    })
  }
}
